@font-face {
	font-family: "Mission Gothic Regular"; src: url('/sites/all/themes/sunlightg/fonts/Mission_Gothic_Regular.otf'),
	url('/sites/all/themes/sunlightg/fonts/mission_gothic_regular-webfont.woff2') format('woff2'),
         url('/sites/all/themes/sunlightg/fonts/mission_gothic_regular-webfont.woff') format('woff');
	  }
@font-face {
	font-family: "Mission Gothic Black";
	src: url('/sites/all/themes/sunlightg/fonts/Mission_Gothic_Black.otf'),
	url('/sites/all/themes/sunlightg/fonts/mission_gothic_black-webfont.woff2') format('woff2'),
         url('/sites/all/themes/sunlightg/fonts/mission_gothic_black-webfont.woff') format('woff');
}
@font-face {
	font-family: "Mission Gothic Thin"; src: url('/sites/all/themes/sunlightg/fonts/Mission_Gothic_Thin.otf'),
	url('/sites/all/themes/sunlightg/fonts/mission_gothic_thin-webfont.woff2') format('woff2'),
         url('/sites/all/themes/sunlightg/fonts/mission_gothic_thin-webfont.woff') format('woff');
	  }
@font-face {
	font-family: "Mission Gothic Bold"; src: url('/sites/all/themes/sunlightg/fonts/Mission_Gothic_Bold.otf'),
	url('/sites/all/themes/sunlightg/fonts/mission_gothic_bold-webfont.woff2') format('woff2'),
         url('/sites/all/themes/sunlightg/fonts/mission_gothic_bold-webfont.woff') format('woff');
	  }
@font-face {
	font-family: "Mission Gothic Light"; src: url('/sites/all/themes/sunlightg/fonts/Mission_Gothic_Light.otf'),
	url('/sites/all/themes/sunlightg/fonts/mission_gothic_light-webfont.woff2') format('woff2'),
         url('/sites/all/themes/sunlightg/fonts/mission_gothic_light-webfont.woff') format('woff'); }
@import url('https://fonts.googleapis.com/css?family=Encode+Sans+Condensed:400,500');
@import url('https://fonts.googleapis.com/css?family=Encode+Sans:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Zilla+Slab:300,300i,400,500,600');

@mixin zillaslab {
	font-family: "Zilla Slab", serif;
}
