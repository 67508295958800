/*SOLIDARITY THEME*/
@import "colors";
@import "fonts";
@import "variables";
@import "footer";
@import "affiliation-block";

body {
  background-color: #fff;
  color: #888D9D;
  background: rgba(255, 255, 255, 0.95);
  font-family: 'Encode Sans Condensed', sans-serif;
  line-height: 20px; /*maybe too broad*/
  padding-top: 82px !important; /*TO FIX WEIRD HEADER SPACE PROB*/
  margin-top: -82px !important; /*TO FIX WEIRD HEADER SPACE PROB*/
  
  @include breakpoint(mobile) {
    color: #666;
    margin-bottom: 40px;
  }
}

body.not-front.admin-menu {
  margin-top: -21px !important;

  #header.stickynav-processed {
    box-shadow: none;
  }

}

.main {
  margin-bottom: 0px;
  height: 0px;
}

h2 {
  font-weight: normal;
  font-size: 20px;
  display: inline-block;
  text-transform: uppercase;
}

/*FROM FRONT PAGE STYLES*/

/*STICKY NAV/Scrolling FIXES*/
body {
  padding-top: 0 !important;
}

.stickynav-processed {
  position: sticky;
  top: 0px;
  z-index: 998;
  width: 100%;
}

.stickynav-active {
  z-index: 1000 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/*TOP PART OF PAGE*/
#header {
  background-color: #FFF;

  .header-section {
    display: table;
  }
}

#block-bean-front-page-banner-block-temp {
  position: absolute;
  top: 82px;
  left: 0px;
}

#block-block-2 {
  background-color: #FFE000;
  margin: auto;
  opacity: .9;
  margin-top: 550px;
  padding-top: 0px;
}

#block-block-2 p {
  font-weight: bold;
  font-size: 5em;
  margin: 0 auto;
  padding: 30px 100px;
  line-height: 1.3em;
  color: #de7f4c;
}

a, a:hover, a:active, a:focus {
  color: #ffd100;
}

a:hover {
  border-bottom: 3px solid #ffd100;
}

h2 {
  font-size: 20px;
  font-family: 'Encode Sans Condensed', sans-serif;
  display: inline-block;
  text-transform: uppercase;
  color: $sg-gray;
  background-color: $sg-yellow;
  padding: 5px 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.header .site-logo {
  padding-top: 10px;
  text-align: left;
}

.views-row {
  clear: none;
}

.opening-statement {
  @include setwidth();
  font-family: "Zilla Slab", serif;
  font-size: 3em;
  margin-top: 0px;
  line-height: 1.5em;
  margin-bottom: 100px;

  @include breakpoint(tablet) {
    @include mobileleft();
    color: #666;
  }

  @include breakpoint(mobile) {
    @include mobiletext();
    margin-bottom: 30px;
    color: #666;
  }
}

p.anchortop {
  margin: 0;
  line-height: 0;
}

p.anchorbottom {}

#fullwidth-middle > .container > .region.region-fullwidth-middle.clearfix {
  @include setwidth();
}

#fullwidth-middle {
  background-image: url(/sites/all/themes/solid/images/response_01.jpg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

/*STRONGER TOGETHER BLOCK. NOT USED*/

#block-block-4 {
  width: calc(100% - 100px);
  margin: auto;
  padding-left: 100px;
}

#block-block-4 .content {
  position: relative;
}

#block-block-4 .content p {
  position: absolute;
  bottom: 0px;
  margin-bottom: 50px;
  z-index: 1;
  display: inline-block;
  color: #FFF;
  font-size: 36px;

  @include breakpoint(mobile) {
    @include mobiletext();
  }
}

#block-block-4 .content p {
  background-color: #F69679;
  padding: 20px 10px;
}

/*ABOUT SECTION*/

#block-block-1 {
  background-image: url('/sites/all/themes/solid/images/MayDay.jpg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 925px;
  vertical-align: middle;
}

.aboutblock {
  @include setwidth();

  @include breakpoint(tablet) {
    margin-left: 0; // necessary or it has double margin on mobile
    margin-right: 0;
    max-width: 100%;
  }

  @include breakpoint(mobile) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

#block-block-1 h2 {
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 40px; /*important for scrolling!*/
}

#block-block-1 .content {
  /*grey text box*/
  margin-top: 40px;
  color: #FFFFFF;
  font-size: 28px;
  font-family: "Zilla Slab", serif;
  position: relative;
  line-height: 36px;
  padding: 20px 0 20px;
  font-size: 16px;
  background-color: #888D9D;
  opacity: .9;

  p {
    opacity: 1;
  }

  @include breakpoint(mobile) {
    @include mobiletext();
  }
}

#block-block-1 p,
#block-block-1 a {
  font-size: 24px;
  line-height: 34px;
}

#block-block-1 a:hover,
#block-block-1 a:active,
#block-block-1 a:focus {
  border-bottom: 3px solid #D5D4D4;
  background-color: transparent;
  text-decoration: none;
}

/* column layout classes used by block 1 */
#block-block-1 {
  .column-container {
    display: flex;
    justify-content: space-between;
    font-family: 'Encode Sans Condensed', sans-serif;

    p, a {
      font-size: 1.2em;
      line-height: 1.6em;
    }

    @include breakpoint(mobile) {
      flex-wrap: wrap;
    }

    &.two-column-layout {
      .column {
        width: 100%;
        max-width: 550px; // half in 1100 width
        box-sizing: border-box;

        @include breakpoint(mobile) {
          width: 100%;
        }
      }

      .column-first {
        padding-right: 15px;

        @include breakpoint(mobile) {
          padding-right: 0;
        }
      }

      .column-second {
        padding-left: 40px;

        @include breakpoint(mobile) {
          padding-left: 0;
        }
      }
    }
  }
}

/*PARTNERS*/
#fullwidth-bottom {
  /*includes partner section AND About section*/
  background-color: #F69679;
}

#block-views-partners-block .view.view-partners.view-id-partners.view-display-id-block.clear-both.clearfix {
  @include setwidth();

  @include breakpoint(tablet) {
    @include mobileleft();
  }
}

#block-views-partners-block .view-header {
  @include setwidth();

  @include breakpoint(tablet) {
    @include mobileleft();
  }
}

.view-partners .view-header p {
  /*we stand...*/
  color: #FFFFFF;
  font-size: 28px;
  font-family: "Zilla Slab", serif;
  position: relative;
  line-height: 36px;
  margin-bottom: 40px;

  @include breakpoint(mobile) {
    @include mobiletext();
  }
}

#block-views-partners-block {
  background-color: #F69679;
  /*padding-top: 100px;*/
}

#block-views-partners-block .view-content {
  @include breakpoint(tablet) {
    @include mobileleft();
  }
}

#block-views-partners-block .content {
  background-color: rgba(246, 150, 121, 0.5);
  position: relative;
  bottom: 50px;
}

#block-views-partners-block .content h2 {
  margin-bottom: 20px;
  margin-top: 40px; /*important for scrolling!*/
  padding: 5px 10px;

  @include breakpoint(mobile) {
    @include mobileleft();
  }
}

#block-views-partners-block .download {
  /*download link container*/
  padding-top: 25px;
  font-size: 18px;
  margin-bottom: 50px;

  @include breakpoint(mobile) {
    @include mobileleft();
  }
}

#block-views-partners-block .download a {
  /*download link*/
  color: #fff;
  font-family: "Zilla Slab", serif;
  font-style: italic;
  text-transform: none;
}

#block-views-partners-block .download a:hover {
  /*download link hover*/
  color: #fff;
  background-color: transparent;
  border-bottom: 3px solid #FFF;
}

#block-views-partners-block .download .fa {
  /*download icon*/
  color: #888d9d;
}

#block-views-partners-block .download .fa:hover {
  /*download icon hover*/
  color: #FFF;
}

#block-views-partners-block .view-content {
  -webkit-columns: 100px 3; /* Chrome, Safari, Opera */
  -moz-columns: 100px 3; /* Firefox */
  columns: 100px 3;
}

#block-views-partners-block .content .views-row {
  /* margin-left: 100px; */
}

#block-views-partners-block .content .views-row .views-field .field-content a {
  color: #FFF;
  font-size: 18px;
  border-bottom: 2px solid #F69679;
}

#block-views-partners-block .content .views-row .views-field .field-content a:hover {
  color: #FFF;
  border-bottom: 2px solid #FFF;
  background-color: transparent;
}

#block-views-partners-block .content .views-row .views-field .field-content a i {
  display: none;
}

/*STRATEGIC REGIONAL GRANTS*/

.strategic {
  @include setwidth();
  color: #FFFFFF;
  font-family: "Zilla Slab", serif;
  font-size: 28px;
  line-height: 36px;

  @include breakpoint(tablet) {
    @include mobiletext();
  }

  p {
    margin-bottom: 20px;

    @include breakpoint(tablet) {
      @include mobileleft();
    }
  }

  h2 {
    @include breakpoint(tablet) {
      @include mobileleft();
    }

    .view-content {
      @include breakpoint(tablet) {
        @include mobileleft();
      }
    }
  }
}

/*MAP SECTION*/

#block-bean-map h2 {
  margin-top: 40px;
}

/*RESPONSIVE STUFF*/

@media screen and (max-width: 768px) {
  #block-views-partners-block .content .views-row .views-field .field-content a i {
    display: none;
  }

  #fullwidth-top {
    background-position: center center;
  }

  #block-views-partners-block .view-content {
    -webkit-columns: 100px 1; /* Chrome, Safari, Opera */
    -moz-columns: 100px 1; /* Firefox */
    columns: 100px 1;
  }

  #block-block-2 p {
    font-size: 2em;
    padding: 20px 100px;
    line-height: 1.3em;
  }

  #block-block-2 p {
    font-size: 2em;
    padding: 20px 100px;
    line-height: 1.3em;
  }

  #block-block-2 {
    margin-top: 410px;
    padding-top: 0px;
  }

  #block-block-1 {
    height: auto;
  }

  #block-block-1 .content {
    margin-top: 30px;
    padding: 20px 40px;
    height: auto;
    font-size: 16px;
  }

//   @include breakpoint(mobile) {
//     @include mobileleft();
//   }
}

@media screen and (max-width: 475px) {
  #block-views-partners-block .content .views-row .views-field .field-content a i {
    display: none;
  }

  #block-block-2 {
    margin-top: 280px;
    padding-top: 0px;
  }
}

/* ----- Text ------------------------ */

h1.title {
  display: none;
}

.lead {
  font-size: 1.2rem;
  font-weight: 300;
  color: #888D9D;
  line-height: 1.5rem;
}

.leadnocaps {
  font-size: 1.2rem;
  font-weight: 300;
  color: #888D9D;
  text-transform: lowercase;
  line-height: 1.5rem;
}

/*AFFILIATES*/

.affiliate-item:hover {
  opacity: 0.8;
  -moz-opacity: 0.8;
  -webkit-opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  cursor: pointer;
}

.affcolor {
  background-color: yellow;
}

#block-bean-map {
  width: calc(100% - 200px);
  @include setwidth();
  margin: auto;
  padding-left: 100px;
  padding-right: 100px;
  background-color: #F69679;

  @include breakpoint(smalldesktop) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

#block-bean-local-affiliate-grid {
  @include setwidth();
  width: calc(100% - 100px);
  margin: auto;
  background-color: #F69679;
  margin-bottom: 65px;
}

#block-block-7 .content {
  padding: 50px 100px;
  margin: auto;
  background-color: #F69679;
}

#block-bean-local-affiliate-grid .field-collection-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  display: -webkit-flex;
  -webkit-justify-content: flex-start;
  -webkit-flex-wrap: wrap;

  @include breakpoint(tablet) {
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }

  @include breakpoint(mobile) {
    justify-content: center;
    -webkit-justify-content: center;
  }
}

#block-block-7 .content h2 {
  /*grassroots h2*/
  background-color: #FFE000;
  margin-bottom: 20px;
  padding: 5px 10px;
  color: #888D9D;
}

#block-block-7 .content, #block-bean-map .field-name-field-opening-text {
  /*Affiliates opening info, grassroots text*/
  color: #FFF;
  font-size: 24px;
  line-height: 34px;
}

#block-bean-local-affiliate-grid .field-collection-view.clearfix.view-mode-full {
  display: inline-block;
  width: calc(25% - 15px);
  margin-right: 14px;

  @include breakpoint(smalldesktop) {
    width: calc(25% - 15px);
    margin-right: 14px;
  }

  @include breakpoint(tablet) {
    width: calc(50% - 15px);
    margin-right: 14px;
  }

  @include breakpoint(mobile) {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
}

.affiliate-item {
  margin: 0 0 15px 0;
  cursor: pointer;
  z-index: 100;
}

.affiliate-header {
  background-color: #FFF;
  height: 65px; //from 100px
  position: relative;
  padding: 20px;

  @include breakpoint(smalldesktop) {
    padding: 10px;
  }

  @include breakpoint(tablet) {
    padding: 10px;
  }

  @include breakpoint(mobile) {
    padding: 20px;
  }
}

.affiliate-header img {
  height: auto; /*was 45*/
  width: 130px;
  max-width: 130px;
  max-height: 70px;

  @include breakpoint(smalldesktop) {
    width: 100px;
    max-width: 100px;
  }

  @include breakpoint(tablet) {
    margin: 10px;
    max-height: 50px;
  }

  @include breakpoint(mobile) {
    /* no specific styles */
  }
}

.affiliate-item[data-target="#modalgray"] .affiliate-header img {
  /*Unidos Only*/
  width: auto;
  max-width: 150px;

  @include breakpoint(smalldesktop) {
    width: auto;
    max-width: 100px;
  }
}

.affiliate-item[data-target="#modalred"] .affiliate-header img {
  /*Unidos Only*/
  width: 150px;
  max-width: 150px;

  @include breakpoint(smalldesktop) {
    width: 100px;
    max-width: 100px;
  }
}

.affiliate-teaser {
  color: #FFF;
  padding: 20px;
  height: 130px;
  max-height: 130px;
  font-size: 1.2em;
  line-height: 1.3em;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;

  @include breakpoint(smalldesktop) {
    font-size: 1.1em;
    height: 140px;
  }

  @include breakpoint(tablet) {
    font-size: 1em;
    height: 150px;
  }

  @include breakpoint(mobile) {
    font-size: 1.4em;
    height: 160px;
  }
}

.affiliate-teaser .field.field-name-field-teaser-text.field-type-text-long.field-label-hidden,
.affiliate-teaser .field-items,
.affiliate-teaser .field-item.even,
.affiliate-teaser .fa.fa-arrow-right {
  display: inline;
}

.affiliate-teaser .fa.fa-arrow-right {
  margin-left: 5px;
}

.affiliate-dot {
  position: absolute;
  right: 15px;
  top: 10px;
  text-align: right;
}

.affiliate__year {
  @include zillaslab;
  font-weight: 600;
  font-size: 1.4em;
}

.affiliate__grant-amount {
  @include zillaslab;
  font-weight: 300;
  font-style: italic;
  margin-top: 20px;
}

.affiliate-item[data-target="#modalpink"] .affiliate-teaser {
  background-color: $affiliate-pink;
}

.affiliate-item[data-target="#modalpink1"] .affiliate-teaser {
  background-color: $affiliate-pink;
}

.affiliate-item[data-target="#modaldkblue"] .affiliate-teaser {
  background-color: $affiliate-dkblue;
}

.affiliate-item[data-target="#modaldkblue1"] .affiliate-teaser {
  background-color: $affiliate-dkblue;
}

.affiliate-item[data-target="#modalorange"] .affiliate-teaser {
  background-color: $affiliate-orange;
}

.affiliate-item[data-target="#modalorange1"] .affiliate-teaser {
  background-color: $affiliate-orange;
}

.affiliate-item[data-target="#modalyellow"] .affiliate-teaser {
  background-color: $affiliate-yellow;
}

.affiliate-item[data-target="#modalyellow1"] .affiliate-teaser {
  background-color: $affiliate-yellow;
}

.affiliate-item[data-target="#modalltblue"] .affiliate-teaser {
  background-color: $affiliate-blue;
}

.affiliate-item[data-target="#modalltblue1"] .affiliate-teaser {
  background-color: $affiliate-blue;
}

.affiliate-item[data-target="#modalred"] .affiliate-teaser {
  background-color: $affiliate-red;
}

.affiliate-item[data-target="#modalred1"] .affiliate-teaser {
  background-color: $affiliate-red;
}

.affiliate-item[data-target="#modalteal"] .affiliate-teaser {
  background-color: $partner-teal;
}

.affiliate-item[data-target="#modalteal1"] .affiliate-teaser {
  background-color: $partner-teal;
}

.affiliate-item[data-target="#modalgreen"] .affiliate-teaser {
  background-color: $partner-green;
}

.affiliate-item[data-target="#modalgreen1"] .affiliate-teaser {
  background-color: $partner-green;
}

.affiliate-item[data-target="#modalpurple"] .affiliate-teaser {
  background-color: $affiliate-purple;
}

.affiliate-item[data-target="#modalpurple1"] .affiliate-teaser {
  background-color: $affiliate-purple;
}

.affiliate-item[data-target="#modalgray"] .affiliate-teaser {
  background-color: $sg-gray;
}

.affiliate-item[data-target="#modalgray1"] .affiliate-teaser {
  background-color: $sg-gray;
}

/*Years*/

.affiliate-item[data-target="#modalpink"] .affiliate-dot {
  color: $affiliate-pink;
}

.affiliate-item[data-target="#modalpink1"] .affiliate-dot {
  color: $affiliate-pink;
}

.affiliate-item[data-target="#modaldkblue"] .affiliate-dot {
  color: $affiliate-dkblue;
}

.affiliate-item[data-target="#modaldkblue1"] .affiliate-dot {
  color: $affiliate-dkblue;
}

.affiliate-item[data-target="#modalorange"] .affiliate-dot {
  color: $affiliate-orange;
}

.affiliate-item[data-target="#modalorange1"] .affiliate-dot {
  color: $affiliate-orange;
}

.affiliate-item[data-target="#modalyellow"] .affiliate-dot {
  color: $affiliate-yellow;
}

.affiliate-item[data-target="#modalyellow1"] .affiliate-dot {
  color: $affiliate-yellow;
}

.affiliate-item[data-target="#modalltblue"] .affiliate-dot {
  color: $affiliate-blue;
}

.affiliate-item[data-target="#modalltblue1"] .affiliate-dot {
  color: $affiliate-blue;
}

.affiliate-item[data-target="#modalred"] .affiliate-dot {
  color: $affiliate-red;
}

.affiliate-item[data-target="#modalred1"] .affiliate-dot {
  color: $affiliate-red;
}

.affiliate-item[data-target="#modalteal"] .affiliate-dot {
  color: $partner-teal;
}

.affiliate-item[data-target="#modalteal1"] .affiliate-dot {
  color: $partner-teal;
}

.affiliate-item[data-target="#modalgreen"] .affiliate-dot {
  color: $partner-green;
}

.affiliate-item[data-target="#modalgreen1"] .affiliate-dot {
  color: $partner-green;
}

.affiliate-item[data-target="#modalpurple"] .affiliate-dot {
  color: $affiliate-purple;
}

.affiliate-item[data-target="#modalpurple1"] .affiliate-dot {
  color: $affiliate-purple;
}

.affiliate-item[data-target="#modalgray"] .affiliate-dot {
  color: $sg-gray;
}

.affiliate-item[data-target="#modalgray1"] .affiliate-dot {
  color: $sg-gray;
}

/*----- MODAL -----*/
.modal.fade {
  top: -900px;
  background-color: #888D9D;
  color: #fff;
  padding: 30px;
  border-radius: 0;
  border: none;

  @include breakpoint(tablet) {
    top: -950px;
  }
}

// Fixup so that menu items are clickable
.modal.fade[aria-hidden="true"] {
  z-index: 0;
}

.modal.fade .modal-header {
  border-bottom: none;
}

.modal.fade .modal-header button.close {
  color: #FFF;
  opacity: 1;
  font-size: 24px;
  z-index: 1000;
  cursor: pointer;
}

.modal-body {
  max-height: none;
  font-size: 16px !important;
}

.modal-body p {
  font-size: 16px !important;
}

.modal-backdrop.fade.in {
  opacity: 0.6;
}

/* MEDIA QUERIES */
@media screen and (min-width: 1400px) {
  #block-bean-map .field-item.even {
    display: flex;
    justify-content: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  #block-bean-map {
    width: calc(100% - 100px);
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
  }

  #block-bean-local-affiliate-grid {
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
  }
}

/* ----- Links ----------------------- */

a:hover, a:active {
  color: #34BAC7;
  background-color: #FFF;
}

.btn {
  background-color: transparent;
  color: #767A96; /*cool grey*/
  border: 1px solid #767A96;
  text-transform: uppercase;
  font-family: "Mission Gothic Regular";
  font-size: 1.2em;
  background-image: none;
  line-height: 1.2em;
  border-radius: 1000px;
  letter-spacing: .1em;
  transition: all 160ms ease-out;
  padding: .25rem .5rem;
  font-size: 0.8rem;
  text-shadow: none;
}

.header {
  margin: 0px;
}

.header .site-logo {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}

.header .site-logo {
  padding-top: 10px;
  text-align: left;
}

/*styles for large logo*/
.header .site-logo img {
  height: 55px;

  @include breakpoint(mobile) {
    height: 45px;
    margin-left: 30px;
  }
}

#header {
  height: 80px;
  padding: 0px;
  border-bottom: 1px solid rgba(47, 55, 64, 0.2);
  box-shadow: 0 5px 0 0 rgba(47, 55, 64, 0.08);
}

#site-name {
  font-weight: 400;
  font-size: 30px;
  position: relative;
  margin-left: -10px;
  padding-left: 0px;
  margin-top: 5px;
}

/*MENUS*/

/*Menu styles*/

div.navbar-inner button.btn.btn-navbar span.icon-bar {
  display: none;
}

@media (max-width: 767px), all {
  .navbar .btn-navbar {
    display: none;
  }
}

#main-menu {
  /*hide menu for now that will later hamburger*/
  display: none;
}

#block-system-main-menu {
  display: inline-block !important;
  float: right;
  margin-right: 80px;
  font-size: 18px;
  font-weight: 400;
}

ul.menu li {
  margin: 0 0 0 2em;
}

ul li.leaf {
  list-style-image: none;
  list-style-type: none;
  display: block;
  float: left;
  text-transform: uppercase;
}

.nav>li>a {
  color: #888D9D;
}

.nav>li>a:hover {
  background-color: transparent;
  border-bottom: 3px solid #888D9D;
  color: #888D9D;
}

.navbar {
  margin-bottom: 0px;
}

/*Hamburger Menu Styles*/
.navbar-inner {
  background-color: #fff;
  background-image: none;
  border: none;
  -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.065);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.065);
  padding: 0px;
  margin-top: 10px;
}

.nav-collapse .nav>li>a:hover {
  background-color: transparent !important;
  color: #F69679;
  border-radius: 0px;
  border-bottom: none;
}

.btn {
  border-radius: 0px;
}

div.navbar-inner button.btn.btn-navbar {
  position: absolute;
  top: 15px;
  z-index: 1000;
  right: 20px;
  background-color: #FFF;
  background-image: none;
  border: none;
  box-shadow: 0px 0px 0px #FFF;
}

div.navbar-inner button.btn.btn-navbar:active,
div.navbar-inner button.btn.btn-navbar:focus {
  outline: none;
}

div.navbar-inner button.btn.btn-navbar span.icon-bar {
  display: block !important;
  width: 28px;
  margin-bottom: 6px;
  height: 2px;
  background-color: #99a1bb;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

nav#main-nav ul.menu.nav li a {
  text-align: center;
}

/*End Hamburger Menu Styles*/

@media screen and (max-width: 768px) {
  body {
    padding-right: 0px;
    padding-left: 0px;
  }

  #logo {
    position: absolute;
    top: 5px;
    left: 4px;
  }

  ul li.leaf {
    line-height: 16px;
    font-size: 12px !important;
    list-style-image: none;
    list-style-type: none;
    display: block;
    float: none;
  }

  #main-menu {
    display: block !important;
    margin-top: 160px;
  }

  #block-system-main-menu {
    margin-top: 10px;
    margin-right: 10px;
    font-size: 12px !important;
    display: none;
  }

  .nav>li>a:hover {
    border-bottom: 1px solid #888D9D;
  }
}

@media screen and (max-width: 465px) {
  #block-system-main-menu {
    margin-right: 20px;
    font-size: 12px !important;
  }
}

/*end Menu styles*/

/*BLOCKS*/

.bean-big-image-block {
  @include setwidth();

  @include breakpoint(tablet) {
    @include mobileleft();
  }
}

/*SlIDES*/

.carousel {
  margin-bottom: 0;
  position: relative;
}

.carousel-indicators {
  position: absolute;
  top: 95%;
  right: 50%;
  z-index: 5;
  margin: 0 0 10px 0;
  list-style: none;
  cursor: pointer;
}

#block-views-full-width-slideshow-block {
  padding-top: 0;
  margin-top: -110px;
  position: relative;
  overflow: hidden;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin-top: 60px;
  }
}

// logged in fixups
body.logged-in #block-views-full-width-slideshow-block {
  padding-top: 0;
  margin-top: -191px;

  @media screen and (min-width: 768px) {
    margin-top: -20px;
  }
}

.views-slideshow-controls-bottom {
  position: absolute;
  bottom: 40px;
  right: 45%;
  z-index: 300;
}

.views-content-field-text-color-options {
  display: inline-block;
}

.views-slideshow-cycle-main-frame {
  width: 100% !important;
  height: auto !important;
  position: relative !important;
}

.views_slideshow_cycle_main .views-slideshow-cycle-main-frame-row {
  height: auto !important;
  width: 100% !important;
  position: relative !important;
}

.views-slideshow-cycle-main-frame-row img {
  height: auto !important;
  width: 100%;
}

.carousel-inner>.item>img {
  width: 100%;
}

.views-slideshow-pager-fields .active {
  text-decoration: none;
}

.sgslide {
  position: relative;
  margin: 0 auto;
  background-color: $sg-coral;

  a:focus, a:hover {
    text-decoration: none;
  }

  &__image {
    height: 100%;
  }

  &__edit {
    position: absolute;
    bottom: 10px;
    left: 10px;

    a {
      color: #FFF;
    }
  }

  &__caption {
    position: absolute;
    max-width: 500px;
    left: 110px;
    top: 40%;

    @include breakpoint(tablet) {
      width: 300px;
    }

    @include breakpoint(mobile) {
      top: 45px;
      left: 10px;
      width: 150px;
    }

    p {
      font-size: 4.6em;
      display: inline-block;
      font-weight: 500;
      text-transform: uppercase;
      background-color: $sg-yellow;
      padding: 10px 5px;
      margin: 2px;
      color: $sg-gray;

      @include breakpoint(tablet) {
        font-size: 2em;
        padding: 10px 5px;
      }

      @include breakpoint(mobile) {
        font-size: 1.2em;
        padding: 3px 2px;
      }
    }
  }

  &__caption.Green {
    p {
      background-color: $sg-green;
    }
  }

  &__caption.Blue {
    p {
      background-color: $sg-blue;
    }
  }

  &__caption.Yellow {
    p {
      background-color: $sg-yellow;
    }
  }

  &__caption.Pink {
    p {
      background-color: $sg-pink;
    }
  }

  &__caption.Coral {
    p {
      background-color: $sg-coral;
    }
  }
}

// Back to Top

#backtotop {
  background: url("/sites/all/themes/solid/images/backtotop.png") no-repeat center center;
}
