
/*SG Colors*/
$sg-yellow: #FFE000;
$sg-coral: #F69679;
$sg-gray:#888D9D;
$sg-coral-dark: #E79E41;
$sg-gray-light:#D5D4D4;
$sg-blue: #94E4ED;
$sg-green: #A4EFCB;
$sg-pink: #F49AC1;

/*OTHER COLORS*/
$aclu-blue: #7c9dc6;
$pp-pink: #ea43a3;
$hias-blue: #4573ac;
$cair-orange: #e58611;
$irc-yellow: #f4c22e;
$unidos-red: #dc4348;
$partner-teal: #00b7b5;
$partner-green: #3DBD62;
$affiliate-pink: #ED1893;
$affiliate-dkblue: #255899;
$affiliate-orange:#EA8203;
$affiliate-yellow:#F8C42A;
$affiliate-blue: #7C9DC6;
$affiliate-red: #E51B24;
$affiliate-purple: #A63EC2;
$affiliate-yellow2: #FFC938;
