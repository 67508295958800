/* _variables.scss */

/**********************
*BREAKPOINTS + Layout*
***********************/
@mixin breakpoint($point) {
  @if $point == smalldesktop {
    @media (min-width: 769px) and (max-width: 1100px) { @content; }
  } @else if $point == tablet {
    @media (max-width: 768px) and (min-width: 481px) { @content; }
  } @else if $point == mobile {
    @media (max-width: 480px) { @content; }
  }
}

@mixin mobileleft {
  margin-left: 40px;
  margin-right: 40px;
}

@mixin mobiletext {
  font-size: 20px;
  line-height: 1.3em;
}

@mixin setwidth {
  max-width: 1100px; /* was 1200 */
  margin: 0 auto;

  @include breakpoint(smalldesktop) {
    max-width: calc(100% - 80px);
  }
  @include breakpoint(tablet) {
    max-width: calc(100% - 80px);
  }
  @include breakpoint(mobile) {
    max-width: calc(100% - 80px);
  }
}
