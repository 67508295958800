@import "variables";

#block-bean-wildcard-giving-link-for-solidar {
  order: 2;
  width: 30%;
	@include breakpoint(smalldesktop) {
    width: 50%;
  }
  @include breakpoint(tablet) {
		order: 1;
    width: 100%;
    margin-right: auto;
		margin-left: auto;
		margin-bottom: 20px;
  }
  @include breakpoint(mobile) {
		order: 1;
    width: 100%;
    margin-right: 40px;
    margin-left: 16px;
		margin-bottom: 20px;
  }
}

.affiliation-block {
  &__link {
    a {
      color: $sg-gray;
      &:hover {
        color: white;
      }
    }
  }
  &__logo {
		img {
			max-width: 200px;
		}
		&:hover {
			opacity: .8;
		}

  }
  &__subtext {
    font-size: 14px;
    // mobile first:
    @include breakpoint(mobile) {
      padding: 0 24px;
    }
    & {
      padding: 0 0 0 24px;
    }
  }
}
