@import "colors";
@import "fonts";
@import "variables";

body a, a {
color: #ffd100;
}

#footer a {
color: $sg-gray;
}

#footer .container {
	@include breakpoint(smalldesktop) {
    width: calc(100% - 80px);
  }
	@include breakpoint(tablet){
		width: 100%;
		margin-left: 16px; // to allow logo to show to the left of regular content, aligning at the right place
		margin-right:40px;
	}
}

.site-footer {
	background-color: $sg-yellow;
	margin-top: 0;
	margin-bottom: 0;
}

.footer-content {
		.region-footer {
      margin: 32px auto 40px; // 32px top will look like 40px above the 2 columns of text, this is necessary for logo positioning
      padding-left: 20px;
      width: 100%;
      max-width: 1100px;
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-start;
      @include breakpoint(smalldesktop) {
        padding-left: 0;
      }
      @include breakpoint(tablet){
				justify-content: center;
				flex-wrap: wrap;
				margin-bottom: 30px;
        width: calc(100% - 80px);
        margin-left: 0;
        margin-right: 0;
			}
			@include breakpoint(mobile){
				justify-content: center;
				flex-wrap: wrap;
				margin-bottom: 15px;

			}
		}
}

#block-block-5{
	padding-top: 18px;
	line-height: 20px;
	font-size: 14px;
	order: 1;
	width: 70%;
	@include breakpoint(smalldesktop) {
    width: 50%;
  }
  @include breakpoint(tablet) {
		order: 2;
		//width: calc(100% - 80px);
    width: 100%;
		margin-right: 0;
		margin-left: 0;
  }
  @include breakpoint(mobile) {
		order: 2;
		//width: calc(100% - 40px);
    width: 100%;
    margin-left: 16px;
    margin-right: 20px;
    padding-top: 0;
  }
}

.addressblock a {
color: $sg-gray;
text-decoration: none;
  &:hover {
    color: #ffd100;
    background-color: white;
    padding: 2px;
    text-decoration: none;
    border-bottom: none;
  }
}

/*Footer content*/
.solidarity-giving-footer--content {
  display: flex;

  .column-one, .column-two {
    width: 33%;
    max-width: 260px;
    padding-right: 30px;
  }

  @include breakpoint(smalldesktop) {
    .column-one, .column-two {
      width: 45%;
    }
  }
  @include breakpoint(tablet) {
    justify-content: space-between;
    padding: 0 0 0 24px;
    .column-one {
      width: 50%;
      padding-right: 18px;
    }
    .column-two {
      width: 50%;
      padding-right: 0px;
    }
  }
  @media(min-width: 768px) and (max-width: 979px) {

  }

  // Katria start working on the size ~480px where the layout/margins aren't perfect next.

  @include breakpoint(mobile) {
    flex-wrap: nowrap;
    //justify-content: space-between;
    flex-direction: column;
    padding: 0 24px;

    .column-one, .column-two {
      width: 100%;
      max-width: 100%;
    }

    .column-one {
      order: 2;
    }

    .column-two {
      order: 1;
    }
  }
}
